import { navigate } from 'gatsby';
import React, { useContext, useState, useRef } from 'react';
import { Col, FormControl, InputGroup, Nav, Row, Tab } from 'react-bootstrap';

import GlobalContext from '../../../context/global-context';
import { useLocaleFromRouter } from '../../../hooks/useLocaleFromRouter';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import { ReactComponent as HelpCircleSVG } from '../../../images/help_circle_outline.svg';
import { LocaleType } from '../../../utils/i18n';
import CustomButton, {
  buttonOptions,
} from '../../global/customButton/customButton';

import './tabs.scss';

const Tabs: React.FC = () => {
  const [activeTab, setActiveTab] = useState('business');
  const { i18n } = useContext(GlobalContext);
  const currentLocale: LocaleType = useLocaleFromRouter();
  const { width } = useWindowDimensions();
  const tabsRef = useRef<any>(null);

  const businessSearchBoxRef: React.RefObject<HTMLInputElement> =
    React.createRef();
  const tenderSearchBoxRef: React.RefObject<HTMLInputElement> =
    React.createRef();

  const submitSearch = () => {
    let slug;
    let input;

    if (activeTab === 'business') {
      slug = i18n?.t('business_registry.slug') ?? 'business-registry';
      input = businessSearchBoxRef.current?.value;
    }
    if (activeTab === 'tenders') {
      slug = i18n?.t('opportunities.slug') ?? 'opportunities-info';
      input = tenderSearchBoxRef.current?.value;
    }

    navigate(`/${currentLocale}/${slug}/?query=${input}`, { replace: true });
  };

  const scrollIntoView = () => {
    if (window.innerWidth < 992) tabsRef.current.scrollIntoView();
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') submitSearch();
  };

  return (
    <div className="tabs" ref={tabsRef}>
      <Tab.Container id="tabs" defaultActiveKey="business">
        <Row className="tab-row">
          <Nav>
            <Col xs={6} lg={3}>
              <Nav.Item
                className={`business-tab ${
                  activeTab === 'business' ? 'selected-tab' : ''
                }`}
              >
                <Nav.Link
                  eventKey="business"
                  onClick={() => setActiveTab('business')}
                >
                  {i18n?.t('home.tabs.search.label')}
                </Nav.Link>
              </Nav.Item>
            </Col>
            <Col xs={6} lg={3}>
              <Nav.Item
                className={`tenders-tab ${
                  activeTab === 'tenders' ? 'selected-tab' : ''
                }`}
              >
                <Nav.Link
                  eventKey="tenders"
                  onClick={() => setActiveTab('tenders')}
                >
                  {i18n?.t('home.tabs.info.label')}
                </Nav.Link>
              </Nav.Item>
            </Col>
          </Nav>
        </Row>

        <Row className="panes">
          <Tab.Content>
            {activeTab === 'business' ? (
              <Tab.Pane eventKey="business" className="search-content">
                <InputGroup>
                  <FormControl
                    ref={businessSearchBoxRef}
                    placeholder={
                      width < 576
                        ? i18n?.t('home.tabs.search.placeholder.mobile')
                        : i18n?.t('home.tabs.search.placeholder')
                    }
                    aria-label="Business search field"
                    onClick={scrollIntoView}
                    onKeyDown={handleKeyDown}
                  ></FormControl>
                  <CustomButton
                    style={buttonOptions.filled_search}
                    onClick={submitSearch}
                  >
                    <span className="label">
                      {i18n?.t('home.tabs.search.cta')}
                    </span>
                  </CustomButton>
                </InputGroup>
              </Tab.Pane>
            ) : null}

            {activeTab === 'tenders' ? (
              <Tab.Pane eventKey="tenders" className="search-content">
                <InputGroup>
                  <FormControl
                    ref={tenderSearchBoxRef}
                    placeholder={i18n?.t(
                      'home.tabs.search.opportunity.placeholder'
                    )}
                    aria-label="Opportunity search field"
                    onClick={scrollIntoView}
                    onKeyDown={handleKeyDown}
                  ></FormControl>
                  <CustomButton
                    style={buttonOptions.filled_search}
                    onClick={submitSearch}
                  >
                    <span className="label">
                      {i18n?.t('home.tabs.search.cta')}
                    </span>
                  </CustomButton>
                </InputGroup>
              </Tab.Pane>
            ) : null}
          </Tab.Content>
        </Row>
      </Tab.Container>
      <div
        className="info-text"
        onClick={() => navigate(i18n ? i18n?.t('faq.slug') : '/en/help')}
      >
        <HelpCircleSVG fill="#434e48" />
        {i18n?.t('home.tabs.infoText')}
      </div>
    </div>
  );
};

export default Tabs;
