import { graphql } from 'gatsby';
import React from 'react';

import Layout from '../components/global/layout/layout';
import Backdrop from '../components/homepage/backdrop/backdrop';
import Featured from '../components/homepage/featured/featured';
import Hero from '../components/homepage/hero/hero';
import Register from '../components/homepage/register/register';
import Tabs from '../components/homepage/tabs/tabs';
import { PageBase } from '../context/global-context';
import { ArticleItem } from '../types/articleListPage';
import {
  HomepageContext,
  HomepageData,
  HomepageLocalizationData,
} from '../types/homepage';
import { getLocales, useI18n } from '../utils/i18n';

export const query = graphql`
  query FeaturedArticles($locale: CreeGraphql_I18NLocaleCode) {
    creegraphql {
      featured: articles(
        locale: $locale
        sort: "publishedAt:desc"
        pagination: { limit: 3 }
      ) {
        data {
          attributes {
            publishedAt
            slug
            summary
            title
            localizations {
              data {
                attributes {
                  summary
                  publishedAt
                  slug
                  title
                }
              }
            }
          }
        }
      }
    }
  }
`;

const Homepage: React.FC<{
  pageContext: HomepageContext;
  data: {
    creegraphql: {
      featured: {
        data: Array<ArticleItem>;
      };
    };
  };
}> = ({ pageContext, data }) => {
  const { translations, locale } = pageContext;

  const i18n = useI18n(translations);
  const locales = getLocales([locale]);
  const localizationsData: HomepageLocalizationData[] = [];

  locales.forEach((l) => {
    localizationsData.push({
      attributes: {
        locale: l,
      },
    });
  });

  const homepage: HomepageData = {
    attributes: {
      locale: locale,
      localizations: {
        data: localizationsData,
      },
    },
  };

  const featured = data.creegraphql.featured.data;

  return (
    <PageBase translations={translations} instance={homepage}>
      <Layout>
        <Backdrop>
          <Hero />
          <Tabs />
        </Backdrop>
        <Featured list={featured} />
        <div className="home-register-section">
          <Register />
        </div>
      </Layout>
    </PageBase>
  );
};

export default Homepage;
