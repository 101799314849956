import React, { useContext } from 'react';
import { Col, Row } from 'react-bootstrap';

import GlobalContext from '../../../context/global-context';
import { useLocaleFromRouter } from '../../../hooks/useLocaleFromRouter';
import { ArticleItem } from '../../../types/articleListPage';
import { LocaleType } from '../../../utils/i18n';
import ArticleCard from '../../global/articleCard/articleCard';
import Lead, { leadOptions } from '../../global/lead/lead';

import './featured.scss';

const Featured: React.FC<{ list: Array<ArticleItem> }> = ({
  list,
}): JSX.Element => {
  const { i18n } = useContext(GlobalContext);
  const currentLocale: LocaleType = useLocaleFromRouter();

  let newsSlug = i18n?.t('news.slug');
  newsSlug = newsSlug ? newsSlug : 'news';

  return (
    <div className="homepage-featured-wrapper">
      <Row className="featured-container">
        {list.map((data, i) => {
          return (
            <Col
              md={4}
              key={`${data.attributes.title}${i}`}
              className="article"
            >
              <ArticleCard data={data} block={true} />
            </Col>
          );
        })}
        <span className="see-more">
          <Lead
            slug={`/${currentLocale}/${newsSlug}/1`}
            style={leadOptions.arrow}
          >
            {i18n?.t('home.featured.lead')}
          </Lead>
        </span>
      </Row>
    </div>
  );
};

export default Featured;
