import { navigate } from 'gatsby';
import React, { useContext } from 'react';
import { Col, Row } from 'react-bootstrap';

import GlobalContext from '../../../context/global-context';
import { useLocaleFromRouter } from '../../../hooks/useLocaleFromRouter';
import { LocaleType } from '../../../utils/i18n';
import { redirectToDashboardBusinessCreate } from '../../../utils/user';
import CustomButton, {
  buttonOptions,
} from '../../global/customButton/customButton';
import './register.scss';

const Register: React.FC = (): JSX.Element => {
  const { i18n, isLogged } = useContext(GlobalContext);

  const currentLocale: LocaleType = useLocaleFromRouter();

  function handleRegisterMyBusinessClick() {
    !isLogged
      ? navigate(`/${currentLocale}/${i18n?.t('register.slug')}`)
      : redirectToDashboardBusinessCreate(currentLocale, i18n);
  }

  return (
    <div className="register">
      <Row>
        <Col md={7} className="info">
          <h3>{i18n?.t('home.register.title')}</h3>
          <p>{i18n?.t('home.register.description')}</p>
          <CustomButton
            style={buttonOptions.filled_light}
            className="cta register-business-cta"
            onClick={handleRegisterMyBusinessClick}
          >
            {i18n?.t('home.register.cta')}
          </CustomButton>
        </Col>
        <Col md={5} className="benefits">
          <div className="list">
            <ul>
              <li>{i18n?.t('home.register.benefits.1')}</li>
              <li>{i18n?.t('home.register.benefits.2')}</li>
              <li>{i18n?.t('home.register.benefits.3')}</li>
              <li>{i18n?.t('home.register.benefits.4')}</li>
            </ul>
          </div>
          <CustomButton
            style={buttonOptions.filled_light}
            className="cta"
            onClick={() =>
              navigate(`/${currentLocale}/${i18n?.t('register.slug')}`)
            }
          >
            {i18n?.t('home.register.cta')}
          </CustomButton>
        </Col>
      </Row>
    </div>
  );
};

export default Register;
