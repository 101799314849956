import { StaticImage } from 'gatsby-plugin-image';
import React, { ReactNode } from 'react';
import './backdrop.scss';

const Backdrop: React.FC<{ children: ReactNode }> = ({
  children,
}): JSX.Element => {
  const renderHeroImage = () => {
    return (
      <div className="backdrop-image-container">
        <StaticImage
          className="backdrop-image"
          placeholder="blurred"
          width={450}
          height={585}
          src="../../../images/northern_spirits.jpg"
          alt="Northern spirits"
        />
      </div>
    );
  };
  return (
    <div className="background">
      <div className='hero-wrap'>
        {renderHeroImage()}
        {children}
      </div>
    </div>
  );
};

export default Backdrop;
