import { StaticImage } from 'gatsby-plugin-image';
import React, { useContext } from 'react';
import { Col } from 'react-bootstrap';

import GlobalContext from '../../../context/global-context';

import './hero.scss';

const Hero = (): JSX.Element => {
  const { i18n } = useContext(GlobalContext);
  return (
    <>
      <div className="hero-image-container">
        <StaticImage
          className="hero-image"
          placeholder="blurred"
          width={1000}
          height={300}
          src="../../../images/northern_spirits.jpg"
          alt="Northern spirits"
        />
      </div>
      <div className="hero-section">
        <Col xs={10} lg={6} className="hero-text">
          <div className="hero-text-container">
            <h1>
              {i18n?.t('home.hero.title.line1')} <br />
              {i18n?.t('home.hero.title.line2')}
            </h1>
            <p>{i18n?.t('home.hero.description')}</p>
          </div>
        </Col>
      </div>
    </>
  );
};

export default Hero;
